import React, { Component } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import jQuery from "jquery";
import * as FlexWebChat from "@twilio/flex-webchat-ui";

export default class AgentStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      agents: [],
      isVisible: window.localStorage.getItem("agentStatusVisible"),
    };
  }
  componentDidMount() {
    this.AgentList();
  }
  AgentList() {
    let agent = "false";
    let channelSid = window.localStorage.getItem('channelSid')? window.localStorage.getItem('channelSid') : 'false';
    if (jQuery("#agent_id").length) {
      agent = document.getElementById("agent_id").value;
    }
    const testURL =
      "https://mm.video/api/get_agents/" +
      document.getElementById("company_id").value +
      "/" +
      agent + "/"+ channelSid;
      
    axios.get(testURL).then((response) => {
      this.setState({
        agents: response.data.data,
      });
    });
  }

  editItem = (event) => {
    this.setState({ isVisible: false });
    window.localStorage.setItem(
      "agentStatusVisible",'false'
    );
  };

  openChatBox = (event) => {
    FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
  };

  render() {
    var agent_div = "";
    if (this.state.agents[0]) {
      var firstAgent = this.state.agents[0];
      var isVisible = this.state.isVisible;

      window.localStorage.setItem("firstAgent", firstAgent.name + " schreiben....");
      if (isVisible === 'true' || isVisible === undefined|| isVisible === null) {
        agent_div = (
          <div className="Twilio-wrapper">
            <FontAwesomeIcon icon={faTimesCircle} onClick={this.editItem} />
            <div className="Twilio-online"  onClick={this.openChatBox}>
              <div className="Twilio-pic">
                <img src={firstAgent.img} alt="img_agent" />
              </div>
              <div className="Twilio-cont">
                <h4>{firstAgent.name} ist online</h4>
                <p>(Chat)</p>
              </div>
            </div>
          </div>
        );
      }
    }

    return <div className="active_agent">{agent_div}</div>;
  }
}
export { AgentStatus };
