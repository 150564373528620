// import React from "react";
import React, { useEffect, useState } from "react";
import AgentImages from "./AgentImages";
import AgentStatus from "./AgentStatus";
import * as FlexWebChat from "@twilio/flex-webchat-ui";
import jQuery from "jquery";

class App extends React.Component {
  state = {};

  localStorageKey = "isEntryPointExpanded";
  localStorageKeyTime = "timeOfExpand";
  flexState = (manager) =>
    manager.store.getState().flex.session.isEntryPointExpanded;

  localState = () => window.localStorage.getItem(this.localStorageKey);
  localStateTime = () => window.localStorage.getItem(this.localStorageKeyTime);
  constructor(props) {
    super(props);
    const { configuration } = props;

    FlexWebChat.Manager.create(configuration)
      .then((manager) => {
        manager.strings.WelcomeMessage =
          "Guten Tag, wie kann ich Ihnen helfen?";
        manager.strings.TypingIndicator = "{{name}} tippt...";
        manager.strings.Today = "Heute";
        manager.strings.Yesterday = "gestern";
        manager.strings.Read = "gesendet";
        manager.strings.Connecting = "Verbindungsaufbau";
        manager.strings.Disconnected = "nicht verbunden";
        manager.strings.SendMessageTooltip = "Nachricht senden";
        manager.strings.InvalidPreEngagementButton = "Mehr erfahren";
        manager.strings.MessageSendingDisabled =
          "Das Senden von Nachrichten wurde deaktiviert";
        manager.strings.MessageCanvasTrayContent =
          "<h6>Vollständig</h6><p>Dieser Chat ist nicht mehr aktiv.</p>";
        manager.strings.MessageCanvasTrayButton = "NEUEN CHAT STARTEN";
        manager.strings.AgentStatusAvailable = "Erhältlich";
        manager.strings.AgentStatusBusy = "Belebt";
        manager.strings.AgentStatusUnavailable = "Nicht verfügbar";
        manager.strings.AgentStatusOffline = "Offline";
        manager.strings.InputPlaceHolder = "Ihre Nachricht...";
        this.setState({ manager });
      })

      .catch((error) => this.setState({ error }));

    FlexWebChat.MainContainer.defaultProps.startEngagementOnInit = false;
    FlexWebChat.MainContainer.defaultProps.Content = "false";
    FlexWebChat.MainContainer.defaultProps.height = "275px";
    FlexWebChat.MainContainer.defaultProps.width = "350px";
    FlexWebChat.EntryPoint.defaultProps.tagline = "";
    FlexWebChat.MainHeader.defaultProps.showTitle = false;
    FlexWebChat.MessagingCanvas.defaultProps.messageStyle = "Rounded";
    FlexWebChat.MessagingCanvas.defaultProps.showReadStatus = true;
    FlexWebChat.MessagingCanvas.defaultProps.showTypingIndicator = true;
    FlexWebChat.MessagingCanvas.defaultProps.showWelcomeMessage = true;
    FlexWebChat.MessagingCanvas.defaultProps.predefinedMessage = false;
    FlexWebChat.MainHeader.Content.replace(<AgentImages key="agents" />, {});
  }

  render() {
    const { manager, error } = this.state;
    if (manager) {
      //window.localStorage.removeItem("agentStatusVisible");

      
      if (window.localStorage.getItem("agentStatusVisible") === null) {       
        window.localStorage.setItem("agentStatusVisible", "true");
      }
      let agent = "false";
      if (jQuery("#agent_id").length) {
        agent = document.getElementById("agent_id").value;
      }
      fetch("https://extreme-ip-lookup.com/json/").then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            manager.updateConfig({
              context: {
                company_id: document.getElementById("company_id").value,
                userip: data.query,
                country: data.country,
                region: data.region,
                friendlyName: "Ihre Nachricht",
                sourceUrl: window.location.href,
                agentId: agent,
              },
            });
          });
        }
      });

      let channelSid =  window.localStorage.getItem(document.getElementById("company_id").value+"channelSid")
        ?  window.localStorage.getItem(document.getElementById("company_id").value+"channelSid")
        : "false";

      const testURL =
        "https://mm.video/api/get_company_details_flex/" +
        document.getElementById("company_id").value +
        "/" +
        agent +
        "/" +
        channelSid;
      fetch(testURL).then((response) => {
        if (response.ok) {
          return response.json().then((data) => {
            let flex_color = data.color;
            let agent_name = data.name;
            jQuery(document.body).append(
              "<style>.agent_avatar:after{background-color: " +
                flex_color +
                ";}</style>"
            );
             window.localStorage.setItem("firstAgent", agent_name + " schreiben....");
            setTimeout(() => {
              jQuery("#root")
                .find("textarea")
                .attr("placeholder", agent_name + " schreiben....");
            }, 10000);
            manager.updateConfig({
              colorTheme: {
                overrides: {
                  Chat: {
                    MessageListItem: {
                      FromOthers: {
                        Bubble: {
                          background: flex_color,
                          color: "#383838",
                        },
                        Avatar: {
                          background: flex_color,
                          color: "#000",
                        },
                        Header: {
                          color: "#000",
                        },
                      },
                      FromMe: {
                        Bubble: {
                          background: "#ECEDF1",
                          color: "#000",
                        },
                        Avatar: {
                          background: "#ECEDF1",
                          color: "#000",
                        },
                        Header: {
                          color: "#000",
                        },
                      },
                    },
                    MessageInput: {
                      Button: {
                        background: flex_color,
                        color: "#fff",
                      },
                    },
                    MessageCanvasTray: {
                      Container: {
                        background: flex_color,
                        color: "#fff",
                      },
                    },
                  },

                  MainHeader: {
                    Container: {
                      background: flex_color,
                      color: "#fff",
                    },
                    Logo: {
                      fill: flex_color,
                    },
                  },

                  EntryPoint: {
                    Container: {
                      background: flex_color,
                      color: "#fff",
                    },
                  },

                  PreEngagementCanvas: {
                    Container: {
                      background: flex_color,
                    },

                    Form: {
                      SubmitButton: {
                        background: flex_color,
                        color: "#fff",
                      },
                    },
                  },
                },
              },
            });
          });
        }
      });

      // get current agent and show profile pic
      setInterval(function () {
        let agent = "false";
        if (jQuery("#agent_id").length) {
          agent = document.getElementById("agent_id").value;
        }
        const channelSid = manager.store.getState().flex.session.channelSid;
        if (channelSid) {
           window.localStorage.setItem(document.getElementById("company_id").value+"channelSid", channelSid);

          const callurl =
            "https://mm.video/api/get_agents/" +
            document.getElementById("company_id").value +
            "/" +
            agent +
            "/" +
            channelSid;

          fetch(callurl).then((response) => {
            if (response.ok) {
              return response.json().then((data) => {
                var currentAgents = data.data;
                if (currentAgents) {
                  jQuery(".agent_avatar").html("");
                  jQuery("#root").find("textarea").attr("placeholder", "");
                  currentAgents.map((item) => {
                     window.localStorage.setItem(
                      "firstAgent",
                      item.name + " schreiben...."
                    );
                    var html_data =
                      '<a href="' +
                      item.link +
                      '" target="_BLANK" title="' +
                      item.name +
                      '" rel="noopener noreferrer"><img src="' +
                      item.img +
                      '" alt="agent" class="img_agents"></a>';
                    jQuery(".agent_avatar").append(html_data);
                    jQuery("#root")
                      .find("textarea")
                      .attr("placeholder", item.name + " schreiben....");
                  });
                }
              });
            }
          });
        }
      }, 10000);

      var now = new Date(window.localStorage.getItem(this.localStorageKeyTime));
      var then = new Date();
      const diffInMs = Math.abs(then - now) / 60000;

      if (diffInMs >= 30) {
        window.localStorage.setItem(this.localStorageKeyTime, new Date());
        window.localStorage.removeItem("visibleFlex");
      }

      FlexWebChat.Actions.on("afterToggleChatVisibility", (payload) => {
        window.localStorage.setItem(
          "user_view",
          manager.store.getState().flex.session.isEntryPointExpanded
        );
      });

      FlexWebChat.Actions.on("beforeToggleChatVisibility", (payload) => {
        jQuery(".Twilio-wrapper").hide();
      });

      const visiblenow = this.flexState(manager).toString();
      var isPageLoad = true;
      if (
        visiblenow === "true" &&
        (window.localStorage.getItem("user_view") === null ||
          window.localStorage.getItem("user_view").toString() === "false")
      ) {
        FlexWebChat.Actions.invokeAction("MinimizeChat");
      }
      if (
        visiblenow === "false" &&
        window.localStorage.getItem("user_view") === null &&
        window.localStorage.getItem("visibleFlex") === null
      ) {
        /*setTimeout(() => {
          FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
          window.localStorage.setItem(this.localStorageKeyTime, new Date());
          window.localStorage.setItem("visibleFlex", "true");

          if (window.localStorage.getItem("firstAgent") != null) {
            var agent_name = localStorage.getItem("firstAgent");
            jQuery("#root")
              .find("textarea")
              .attr("placeholder", agent_name + " schreiben....");
          } else {
          }

        }, 10000);*/
      } else {
        jQuery(".Twilio-wrapper").hide();
      }
      if (window.localStorage.getItem("firstAgent") != null && window.localStorage.getItem("firstAgent") !== '')  {
        var agent_name =  window.localStorage.getItem("firstAgent");
        manager.strings.InputPlaceHolder = agent_name;
        //jQuery("#root").find("textarea").attr("placeholder", agent_name);
      } else {
        //manager.strings.InputPlaceHolder = 'Ihre Nachricht...';
        jQuery("#root")
          .find("textarea")
          .attr("placeholder", "Ihre Nachricht...");
      }

      FlexWebChat.Actions.addListener("afterSendMessage", (payload) => {
        manager.chatClient.addListener("messageAdded", (message) => {
          if (
            manager.store.getState().flex.session.isEntryPointExpanded === false
          ) {
            FlexWebChat.Actions.invokeAction("ToggleChatVisibility");
            jQuery(".Twilio-wrapper").hide();
          }
        });
      });
      
      if (diffInMs >= 1440) {
        window.localStorage.removeItem("agentStatusVisible");
        window.localStorage.removeItem(document.getElementById("company_id").value+"channelSid");
      }

      let agentStatus;
      if (isPageLoad) {
        agentStatus = <AgentStatus key="agents" />;
        isPageLoad = false;
      } else {
        agentStatus = "";
      }

      if (manager.store.getState().flex.session.isEntryPointExpanded === true) {
        jQuery(".Twilio-wrapper").hide();
        agentStatus = "";
      }
      return (
        <>
          <div>{agentStatus}</div>
          <>
            <FlexWebChat.ContextProvider manager={manager}>
              <FlexWebChat.RootContainer />
            </FlexWebChat.ContextProvider>
          </>
        </>
      );
    }

    if (error) {
      console.error("Failed to initialize Flex Web Chat", error);
    }

    return null;
  }
}

export default App;
