import React, { Component } from "react";
import axios from "axios";
import jQuery from "jquery";

export default class AgentImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agents: [],
    };
  }

  componentDidMount() {
    this.AgentList();
  }
  AgentList() {
    let agent = "false";
    let channelSid = window.localStorage.getItem(
      document.getElementById("company_id").value + "channelSid"
    )
      ? window.localStorage.getItem(
          document.getElementById("company_id").value + "channelSid"
        )
      : "false";

    if (jQuery("#agent_id").length) {
      agent = document.getElementById("agent_id").value;
    }

    const testURL =
      "https://mm.video/api/get_agents/" +
      document.getElementById("company_id").value +
      "/" +
      agent +
      "/" +
      channelSid;

    axios.get(testURL).then((response) => {
      if (
        window.localStorage.getItem("firstAgent") == null ||
        window.localStorage.getItem("firstAgent") === ""
      ) {
        window.localStorage.setItem(
          "firstAgent",
          response.data.data["0"].name + " schreiben...."
        );
      }
      this.setState({
        agents: response.data.data,
      });
    });
  }
  render() {
    const agents_div = this.state.agents.map((item) => (
      <a
        href={item.link}
        target="_BLANK"
        title={item.name}
        rel="noopener noreferrer"
      >
        <img src={item.img} alt="agent" className="img_agents" />
      </a>
    ));

    return <div className="agent_avatar">{agents_div}</div>;
  }
}
export { AgentImages };
